import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Google, Rectangle47, Rectangle48 } from '../../assets';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const data = localStorage.getItem("login");
        if (data === 'loginKey') {
            setIsLoggedIn(true);
            navigate('/dashboard/girls'); // Automatically redirect to dashboard
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api2.eromantic.ai/api/v1/login/', {
                'email': email,
                "password": password,
            });

            // Assuming the API returns a token on successful login
            if (response.status === 200) {
                await Swal.fire({
                    title: "Welcome, Login In Successfully.",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })
                localStorage.setItem("token", response.data.access); // Store JWT token
                localStorage.setItem("refreshToken", response.data.refresh); // Store refresh token if provided
                localStorage.setItem("login", response.data.Logger);
                localStorage.setItem("u_id", response.data.u_id);
                setIsLoggedIn(true);
                navigate('/dashboard/girls'); // Navigate to the dashboard
            }
        } catch (error) {
            toast.error(<>
                {'Invalid email or password.'}
                <br />
                {'Please try again.'}
            </>, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error(error);
            setError('Invalid email or password. Please try again.');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("login");
        setIsLoggedIn(false);
    };

    return (
        <div>
            <ToastContainer />
            <div>
                <div className='bg-black w-full flex justify-between font-josefin-sans h-[700px] md:h-[965px] lg:h-[100vh]'>
                    <div className='relative hidden md:block md:w-[45%]'>
                        <img
                            src={Rectangle47}
                            alt='Rectangle47'
                            className='w-full object-cover md:h-[965px] lg:h-[100vh]'
                        />
                    </div>
                    <div className='relative w-full bg-white md:w-[55%] flex justify-center items-center'>
                        <button
                            type="button"
                            className="btn absolute top-3 left-3 text-lg text-[#ff0022] cursor-pointer flex items-center"
                            onClick={() => navigate('/')}
                        >
                            <i className="fa-solid fa-circle-arrow-left mr-2"></i>Back
                        </button>
                        <div className="text-white flex flex-col items-center">
                            <img src="/assets/img/logowh.png" alt="Icon" class="w-[20rem] rounded-xl mb-5" />
                            <h4 className="font-sans text-black text-[20px] md:text-[22px] lg:text-[26px] xl:text-[33px] font-semibold leading-snug tracking-normal text-blue-gray-900">
                                LOGIN TO EROMANTIC<span className='text-red-700'>.AI</span>
                            </h4>

                            <form className="mt-5 lg:mt-8 mb-2 w-[278px] md:w-[330px] lg:w-[400px] xl:w-[500px]" onSubmit={handleLogin}>
                                <div className="flex flex-col gap-6 mb-1">
                                    <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[278px] md:min-w-[300px]">
                                        <input
                                            placeholder="Enter your email address"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="peer text-black h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                            required
                                        />
                                    </div>
                                    <div className="relative h-11 md:h-12 lg:h-14 w-full min-w-[278px] md:min-w-[300px]">
                                        <input
                                            type="password"
                                            placeholder="Enter your password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="peer text-black h-full w-full rounded-md border-2 border-blue-gray-200 bg-transparent px-5 py-3 font-sans text-lg font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-500 focus:outline-0"
                                            required
                                        />
                                    </div>
                                </div>
                                {error && <p className="text-red-500 text-sm">{error}</p>}
                                <NavLink to="/ResetPassword" className="text-base text-black border-b-2 border-gray-500 w-56 cursor-pointer" >Forgot your password? Reset it</NavLink>
                                <button
                                    className="mt-6 block w-full select-none rounded-lg bg-[#FF0013] py-2.5 lg:py-3 px-6 text-center align-middle font-sans text-[20px] lg:text-[23px] xl:text-[25px] font-semibold text-white shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85"
                                    type="submit">
                                    Log In
                                </button>
                                <div className='flex items-center justify-center text-center my-4 gap-3'>
                                    <hr className='border-1 w-60 ' />
                                    <p className=" text-[20px] lg:text-[22px] font-light text-black">OR</p>
                                    <hr className='border-1 w-60' />
                                </div>
                                <NavLink to='/dashboard/girls'>
                                    <button
                                        className="w-full text-black select-none rounded-lg bg-transparent py-2.5 lg:py-3 text-center align-middle font-sans text-[18px] lg:text-[20px] xl:text-[22px] font-normal shadow-md transition-all hover:shadow-lg focus:opacity-85 active:opacity-85 flex items-center justify-center border-2 border-[#6b6b6b] gap-4"
                                        type="button">
                                        <img src={Google} alt='Google' />
                                        Continue with Google
                                    </button>
                                </NavLink>
                                <div>
                                    <div className='text-[17px] text-black lg:text-[19px] text-center mt-5'>Don't have an account yet? <Link to={'/join'}><span className='text-[#FF0013] cursor-pointer'>Sign Up</span></Link></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Login;
