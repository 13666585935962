import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SignUp from '../frontend/components/SignUp';
import LandingPage from '../frontend/frontendPages/LandingPage';
import ComingSoon from '../frontend/components/ComingSoon/ComingSoon';
import Layout from '../admin/layouts/Layout';

import Dashbord from '../admin/AdminPages/dashbord';
import PrivateRoute from './PrivateRoute';
import BlogList from '../admin/AdminPages/blogs/blogListPage';
import CreateBlog from '../admin/AdminPages/blogs/blogListPage/CreateBlog';
import EditBlog from '../admin/AdminPages/blogs/blogListPage/EditBlog';
import EditHelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage/EditHelpBlog';
import SingleBlog from '../admin/AdminPages/blogs/blogListPage/SingleBlog';
import SingleBlogUser from '../frontend/frontendPages/Blog/SingleBlogUser';
import Category from '../admin/AdminPages/blogs/categoryPage';
import AddCategory from '../admin/AdminPages/blogs/categoryPage/AddCategory';
import AllUser from '../admin/AdminPages/users/allUsersPage';
import ActiveUser from '../admin/AdminPages/users/activeUserPage';
import InactiveUser from '../admin/AdminPages/users/inactiveUsersPage';
import AddUser from '../admin/AdminPages/users/allUsersPage/AddUser';
import UserProfile from '../admin/AdminPages/users/UserProfile';
import Faqs from '../admin/AdminPages/faqs';
import AddFaqs from '../admin/AdminPages/faqs/AddFaqs';
import Invoice from '../admin/AdminPages/invoice';
import CreateInvoice from '../admin/AdminPages/invoice/CreateInvoice';
import OurStaff from '../admin/AdminPages/ourStaff';
import AddStaff from '../admin/AdminPages/ourStaff/AddStaff';
import EditStaff from '../admin/AdminPages/ourStaff/EditStaff';
import MainCategory from '../admin/AdminPages/helpDesk/mainCategoryPage';
import AddMainCategory from '../admin/AdminPages/helpDesk/mainCategoryPage/AddMainCategory';
import SubCategory from '../admin/AdminPages/helpDesk/subCategoryPage';
import AddSubCategory from '../admin/AdminPages/helpDesk/subCategoryPage/AddSubCategory';
import HelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage';
import CreateHelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage/CreateHelpBlog';
import SingleHelpBlog from '../admin/AdminPages/helpDesk/helpBlogPage/SingleHelpBlog';
import LayoutDesbord from '../frontend/layouts/LayoutDesbord';
import Blog from '../frontend/frontendPages/Blog';
import SingleBlogs from '../frontend/frontendPages/Blog/SingleBlogs';
import General from '../admin/AdminPages/settings/generalPage';
import SeoSetting from '../admin/AdminPages/settings/seoSetting';
import LoginAdmin from '../admin/AdminPages/login';
import RegisterAdmin from '../admin/AdminPages/RegisterAdmin';
import Login from '../frontend/components/Login';
import ResetPassword from '../frontend/components/Reset';
import LandingPageFrontend from '../dashboard/dashboardPages/landingPage';
import AccountProfile from '../dashboard/dashboardPages/accountProfile';
import PricePlan from '../frontend/frontendPages/PricePlan';
import LayoutFrontend from '../dashboard/layouts/LayoutFrontend';
import Referrals from '../dashboard/dashboardPages/referrals';
import Discover from '../dashboard/dashboardPages/discover';
import ImageView from '../dashboard/dashboardPages/discover/ImageView';
import Gallery from '../dashboard/dashboardPages/gallery';
import Girls from '../dashboard/dashboardPages/girls';
import Saved from '../dashboard/dashboardPages/girls/Saved';
import ByEromanticai from '../dashboard/dashboardPages/girls/ByEromanticai';
import Generations from '../dashboard/dashboardPages/generations';
import Modelverification from '../dashboard/dashboardPages/modelVerification';
import Modality from '../dashboard/dashboardPages/modality';
import Creators from '../dashboard/dashboardPages/creators';
import CreatorsProfile from '../dashboard/dashboardPages/creators/CreatorsProfile';
import ModelverificationNextPage from '../dashboard/dashboardPages/modelVerification/ModelverificationNextPage';
import SiteDetail from '../admin/AdminPages/settings/seoSetting/SiteDetail';
import SeoContent from '../admin/AdminPages/settings/seoSetting/SeoContent';
import Support from '../dashboard/dashboardPages/support';
import SupportCategory from '../dashboard/dashboardPages/support/SupportCategory';
import CategoryDiscription from '../dashboard/dashboardPages/support/CategoryDiscription';
import CookiePolicy from '../frontend/extrapage/CookiePolicy';
import PrivacyPolicy from '../frontend/extrapage/PrivacyPolicy';
import TermsOfService from '../frontend/extrapage/TermsOfService';
import Dreamchat from '../dashboard/dashboardPages/DreamChat.js';
import CreateDreamGF from '../dashboard/dashboardPages/CreateDreamGF/index.js';
import Aidreamchat from '../dashboard/dashboardPages/CreateDreamGF/Aidreamchat.js';


const MainRoute = () => {
    const [validation ,setValidation] = useState()

    const hasVisited = localStorage.getItem("hasVisited") === "true";
    
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const login = localStorage.getItem('login');
 
    useEffect(() => {
        console.log(token);
        
        setValidation( token );
    }, []);


    return (
        <Routes>
            {hasVisited ? (
                <>
                    {/* Frontend Routes */}
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/login" element={<LayoutDesbord><Login /></LayoutDesbord>} /> */}
                    <Route path="/ResetPassword" element={<ResetPassword />} />
                    <Route path="/join" element={<SignUp />} />
                    <Route path="/blogs" element={<LayoutDesbord><Blog /></LayoutDesbord>} />
                    <Route path="/singleblogs" element={<LayoutDesbord><SingleBlogs /></LayoutDesbord>} />
                    <Route path="/comingsoon" element={<ComingSoon />} />
                    <Route path="/priceplan" element={<LayoutDesbord><PricePlan /></LayoutDesbord>} />
                    <Route path="/terms-of-service" element={<LayoutDesbord><TermsOfService /></LayoutDesbord>} />
                    <Route path="/privacy-policy" element={<LayoutDesbord><PrivacyPolicy /></LayoutDesbord>} />
                    <Route path="/cookie-policy" element={<LayoutDesbord><CookiePolicy /></LayoutDesbord>} />

                    {/* Admin Routes */}
                    <Route path="/admin" element={<LoginAdmin />} />
                    <Route path="/admin/dashbord" element={<PrivateRoute><Layout><Dashbord /></Layout></PrivateRoute>} />
                    <Route path="/admin/blogList" element={<PrivateRoute><Layout><BlogList /></Layout></PrivateRoute>} />
                    <Route path="/admin/createBlog" element={<PrivateRoute><Layout><CreateBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/EditBlog/:blogId" element={<PrivateRoute><Layout><EditBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/singleBlog" element={<PrivateRoute><Layout><SingleBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/singleBlog/:blogId" element={<PrivateRoute><Layout><SingleBlog /></Layout></PrivateRoute>} />
                    <Route path="/singleBlog/:slug" element={<LayoutDesbord><SingleBlogUser /></LayoutDesbord>} />
                    <Route path="/admin/category" element={<PrivateRoute><Layout><Category /></Layout></PrivateRoute>} />
                    <Route path="/admin/addCategory" element={<PrivateRoute><Layout><AddCategory /></Layout></PrivateRoute>} />
                    <Route path="/admin/allUser" element={<PrivateRoute><Layout><AllUser /></Layout></PrivateRoute>} />
                    <Route path="/admin/activeUser" element={<PrivateRoute><Layout><ActiveUser /></Layout></PrivateRoute>} />
                    <Route path="/admin/inactiveUser" element={<PrivateRoute><Layout><InactiveUser /></Layout></PrivateRoute>} />
                    <Route path="/admin/addUser" element={<PrivateRoute><Layout><AddUser /></Layout></PrivateRoute>} />
                    <Route path="/admin/userProfile" element={<PrivateRoute><Layout><UserProfile /></Layout></PrivateRoute>} />
                    <Route path="/admin/faqs" element={<PrivateRoute><Layout><Faqs /></Layout></PrivateRoute>} />
                    <Route path="/admin/addFaqs" element={<PrivateRoute><Layout><AddFaqs /></Layout></PrivateRoute>} />
                    <Route path="/admin/invoice" element={<PrivateRoute><Layout><Invoice /></Layout></PrivateRoute>} />
                    <Route path="/admin/createInvoice" element={<PrivateRoute><Layout><CreateInvoice /></Layout></PrivateRoute>} />
                    <Route path="/admin/ourStaff" element={<PrivateRoute><Layout><OurStaff /></Layout></PrivateRoute>} />
                    <Route path="/admin/addStaff" element={<PrivateRoute><Layout><AddStaff /></Layout></PrivateRoute>} />
                    <Route path="/staff/edit_staff/:staffId" element={<PrivateRoute><Layout><EditStaff /></Layout></PrivateRoute>} />
                    <Route path="/admin/mainCategory" element={<PrivateRoute><Layout><MainCategory /></Layout></PrivateRoute>} />
                    <Route path="/admin/addMainCategory" element={<PrivateRoute><Layout><AddMainCategory /></Layout></PrivateRoute>} />
                    <Route path="/admin/subCategory" element={<PrivateRoute><Layout><SubCategory /></Layout></PrivateRoute>} />
                    <Route path="/admin/addSubCategory" element={<PrivateRoute><Layout><AddSubCategory /></Layout></PrivateRoute>} />
                    <Route path="/admin/helpBlog" element={<PrivateRoute><Layout><HelpBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/EditHelpBlog/:blogId" element={<PrivateRoute><Layout><EditHelpBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/createHelpBlog" element={<PrivateRoute><Layout><CreateHelpBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/singleHelpBlog" element={<PrivateRoute><Layout><SingleHelpBlog /></Layout></PrivateRoute>} />
                    <Route path="/admin/generalPage" element={<PrivateRoute><Layout><General /></Layout></PrivateRoute>} />
                    <Route path="/admin/seoSetting" element={<PrivateRoute><Layout><SeoSetting /></Layout></PrivateRoute>} />
                    <Route path="/admin/SiteDetail" element={<PrivateRoute><Layout><SiteDetail /></Layout></PrivateRoute>} />
                    <Route path="/admin/SeoContent" element={<PrivateRoute><Layout><SeoContent /></Layout></PrivateRoute>} />

                    {/* Dashboard Routes */}
                    {
                        validation ?
                            <>
                                <Route path="/dashboard" element={<LayoutFrontend><LandingPageFrontend /></LayoutFrontend>} />
                                <Route path="/DreamChat" element={<LayoutFrontend><Dreamchat /></LayoutFrontend>} />
                                <Route path="/CreateDreamGF" element={<LayoutFrontend><CreateDreamGF /></LayoutFrontend>} />
                                <Route path="/BoyFriend" element={<LayoutFrontend><CreateDreamGF /></LayoutFrontend>} />
                                <Route path="/Aidreamchat" element={<LayoutFrontend><Aidreamchat /></LayoutFrontend>} />
                                <Route path="/dashboard/accountProfile" element={<LayoutFrontend><AccountProfile /></LayoutFrontend>} />
                                <Route path="/dashboard/referrals" element={<LayoutFrontend><Referrals /></LayoutFrontend>} />
                                <Route path="/dashboard/discover" element={<LayoutFrontend><Discover /></LayoutFrontend>} />
                                <Route path="/dashboard/ImageView/:id" element={<LayoutFrontend><ImageView /></LayoutFrontend>} />
                                <Route path="/dashboard/gallery" element={<LayoutFrontend><Gallery /></LayoutFrontend>} />
                                <Route path="/dashboard/girls" element={<LayoutFrontend><Girls /></LayoutFrontend>} />
                                <Route path="/dashboard/Saved" element={<LayoutFrontend><Saved /></LayoutFrontend>} />
                                <Route path="/dashboard/ByEromanticai" element={<LayoutFrontend><ByEromanticai /></LayoutFrontend>} />
                                <Route path="/dashboard/generations" element={<LayoutFrontend><Generations /></LayoutFrontend>} />
                                <Route path="/dashboard/modelVerification" element={<LayoutFrontend><Modelverification /></LayoutFrontend>} />
                                <Route path="/dashboard/modality" element={<LayoutFrontend><Modality /></LayoutFrontend>} />
                                <Route path="/dashboard/creators" element={<LayoutFrontend><Creators /></LayoutFrontend>} />
                                <Route path="/dashboard/CreatorsProfile" element={<LayoutFrontend><CreatorsProfile /></LayoutFrontend>} />
                                <Route path="/dashboard/ModelverificationNextPage" element={<LayoutFrontend><ModelverificationNextPage /></LayoutFrontend>} />
                                <Route path="/dashboard/support" element={<LayoutFrontend><Support /></LayoutFrontend>} />
                                <Route path="/dashboard/SupportCategory" element={<LayoutFrontend><SupportCategory /></LayoutFrontend>} />
                                <Route path="/dashboard/CategoryDiscription" element={<LayoutFrontend><CategoryDiscription /></LayoutFrontend>} />
                            </>
                            :
                            <Route path="/" element={<LayoutFrontend><LandingPage /></LayoutFrontend>} />
                    }
                {/* {/* } */}
                </>
            ) : (
                <Route path="/" element={<LayoutFrontend><LandingPage /></LayoutFrontend>} />
            )}
            <Route path="/" element={<LayoutFrontend><LandingPage /></LayoutFrontend>} />
        </Routes>
    );
};

export default MainRoute;
