import React from "react";

function Blogs() {
    return (
        <div>
            <section class="pb-24 ">
                <div class="">
                    <h2 class="font-manrope text-[red] text-4xl font-bold text-gray-900 text-center mb-[5px]">Our latest  blog</h2>
                    <h4 class="font-manrope text-2xl font-bold text-gray-900 text-center mb-[5px]">FROM OUR AI GIRLFRIEND BLOG</h4>
                    <h4 class="font-manrope text-xl font-bold text-gray-900 text-center mb-16">Curious about what an AI girlfriend is? In our blog you can find all the necessary information about the topic AI girlfriend</h4>
                    <div class="flex justify-center  gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">
                        <div class="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-2xl">
                            <div class="flex items-center">
                                <img src="https://api.eromantic.ai/media/blog_images/18_Ai_girl_friend_KDgH8Os.jpg" alt="blogs tailwind section" class="h-72 object-cover rounded-t-2xl w-full object-cover" />
                            </div>
                            <div class="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                <span class="text-[#99000b] font-medium mb-3 block">Jan 01, 2023</span>
                                <h4 class="text-xl text-gray-900 font-medium leading-8 mb-5">Are Virtual AI Girlfriends the Future of Romance in the Digital Age?</h4>
                                <p class="text-gray-500 leading-6 mb-10">Romance is no different in a period where technology is ingrained in every part of our lives. A..</p>
                                <a href="javascript:;" class="cursor-pointer text-lg text-[#99000b] font-semibold">Read more..</a>
                            </div>
                        </div>
                        <div class="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-2xl">
                            <div class="flex items-center">
                                <img src="https://api.eromantic.ai/media/blog_images/image_72_1_EbArIIp.png" alt="blogs tailwind section" class="h-72 object-cover rounded-t-2xl w-full object-cover" />
                            </div>
                            <div class="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                <span class="text-[#99000b] font-medium mb-3 block">Feb 01, 2023</span>
                                <h4 class="text-xl text-gray-900 font-medium leading-8 mb-5">Having a Virtual Partner in 2024: Bliss or Curse?</h4>
                                <p class="text-gray-500 leading-6 mb-10">By 2024, the idea of virtual partners had become mainstream rather than just a sci-fi..</p>
                                <a href="javascript:;" class="cursor-pointer text-lg text-[#99000b] font-semibold">Read more..</a>
                            </div>
                        </div>
                        <div class="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-2xl">
                            <div class="flex items-center">
                                <img src="	https://api.eromantic.ai/media/blog_images/wdwdwdw.png" alt="blogs tailwind section" class="h-72 object-cover rounded-t-2xl w-full object-cover" />
                            </div>
                            <div class="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                <span class="text-[#99000b] font-medium mb-3 block">Mar 01, 20233</span>
                                <h4 class="text-xl text-gray-900 font-medium leading-8 mb-5">How Do AI Porn Video Generators Ensure Privacy and Security for Users</h4>
                                <p class="text-gray-500 leading-6 mb-10">Artificial intelligence (AI) has significantly advanced several industries, incl..</p>
                                <a href="javascript:;" class="cursor-pointer text-lg text-[#99000b] font-semibold">Read more..</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}


export default Blogs;
