import { useState, useEffect } from "react";
import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";


export const Sidebar = ({ isOpen }) => {
    const [active, setActive] = useState(1);
    const [Userlogger, setUserlogger] = useState(null);
    const [usercheck, setUsercheck] = useState(null);
    const goto = index => setActive(index);
    const navigate = useNavigate();

    useEffect(() => {
        setUsercheck(localStorage.getItem('hasVisited'));
        setUserlogger(localStorage.getItem('login'));
    }, []); // Add dependency array to avoid infinite loop

    const navItems = [
        { label: "DISCOVER", icon: <RiCompassDiscoverLine />, link: Userlogger ? "/dashboard/discover" : "/login" },
        { label: "GIRLS", icon: <TbGenderDemigirl />, link: Userlogger ? "/dashboard/girls" : "/login" },
        { label: "BOYS", icon: <TbGenderDemiboy />, link: Userlogger ? "/dashboard/girls" : "/login" },
        { label: "GENERATIONS", icon: <RiAiGenerate />, link: Userlogger ? "/dashboard/generations" : "/login" },
        { label: "PREMIUM", icon: <TbPremiumRights />, link: Userlogger ? "/dashboard/creators" : "/login" },
        { label: "GUIDE", icon: <TbArrowGuide />, link: Userlogger ? "/dashboard/girls" : "/login" }
    ];

    return (
        <>
            {usercheck ?
                <aside className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
                    <div className="inner">
                        <nav className="menu mt-3" >
                            {navItems.map((item, index) => (
                                <Link to={item.link} key={item.label} style={{ textDecoration: 'none' }}>
                                    <button
                                        className={active === index ? "active" : ""}
                                        onClick={() => goto(index)}
                                    >
                                        <span style={{ fontSize: '30px', color: '#000' }}>{item.icon}</span>
                                        <p style={{ fontSize: '20px', color: '#000' }}>{item.label}</p>
                                    </button>
                                </Link>
                            ))}
                        </nav>
                    </div>
                </aside>
                :
                ""
            }


        </>
    );
};

export default Sidebar;