import React, { useEffect, useState } from 'react';
import { AiFillLike } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa6';
import { IoIosTime } from 'react-icons/io';
// import Saved from './Saved';
// import ByEromanticai from './ByEromanticai';
import { useNavigate } from "react-router-dom";
import { img1, img3, img4, img7, pg12, pg22, pg23, pg24, pg25, pg26, brown1 } from '../../assets'
import Characters from "./LandingPage/Characters";
import OurcommunitySection from "./LandingPage/OurcommunitySection";
import Accordion from "./LandingPage/Accordion";
import Blogs from "./LandingPage/blogs";
import Footer from "../components/Footer";
import LandingPopup from "./LandingPopup";

const LandingPage = ({ activeTab, handleActiveTab }) => {
    console.log(activeTab, "---------------");

    const [selected, setSelected] = useState('Saved');
    const [showPopup, setShowPopup] = useState(false);

    // const Saveddata = [
    //     { name: 'Saved', icon: <FaHeart size={20} color='#616161' /> },
    //     { name: 'By EROMANTIC.AI' },
    //     { name: 'Trending', icon: <AiFillLike size={20} color='#616161' /> },
    //     { name: 'Most Recent', icon: <IoIosTime size={20} color='#616161' /> },
    // ];

    const navigate = useNavigate();

    const imgdata = [
        { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }, { images: img1, }, { images: img4, }, { images: img3, }, { images: img7, }
    ]

    const images1 = [{ src: pg12 }, { src: pg22 }, { src: pg23 }, { src: brown1 }, { src: pg25 }, { src: pg26 }, { src: pg12 }, { src: pg22 }];
    
    const CreateDreamGF = () => {
        const token = localStorage.getItem('token');
        console.log(token,"222222222222222222222222")
        if (token == null) {
            navigate("/login");
        } else {
            navigate("/CreateDreamGF");
        }
    };
    const CreateDreamBf = () => {
        const token = localStorage.getItem('token');
        if (token == 'true') {
            navigate('/BoyFriend')
        }
        navigate("/login");
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds

    const handleClosePopup = () => {
        window.location.reload();
        setShowPopup(false);
    };
    useEffect(() => {
        const checkloger = localStorage.getItem('hasVisited');
        if (checkloger == "true") {
            setIsLoggedIn(true);
            setTimeLeft(3600); // Reset the timer
        }
        { formatTime(timeLeft) }
        let timer;
        if (isLoggedIn) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        alert("You have been logged out due to inactivity.");
                        setIsLoggedIn(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [isLoggedIn]);


    useEffect(() => {
        const hasVisited = localStorage.getItem("hasVisited");
        if (!hasVisited) {
            setShowPopup(true);
            localStorage.setItem("hasVisited", "true");
            const timer = setTimeout(() => {
                localStorage.removeItem("hasVisited");
            }, 3600000);
            return () => clearTimeout(timer);
        }
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <>
            <div className='px-2 xl:px-10 pb-5 text-white font-josefin-sans'>
                <div className="bg-gradient-to-r from-red-600 h-[5rem] px-10 to-white w-full flex justify-between items-center">
                    <div className='text-[24px]'>First Subscription</div>
                    <div className='border-2 px-5 border-[#99000B] rounded-[50px] p-2 px-5 rounded-full transition-colors duration-300 bg-[#99000B] text-white'>Up to 70% OFF</div>
                    <div className='border-2 px-5 border-[#807D7D] rounded-[50px] p-2 px-5 rounded-full transition-colors duration-300 bg-[#807D7D] text-white'> {formatTime(timeLeft)}:00</div>
                </div>

                {
                    activeTab == "Girlfriend" ?
                        <>
                            <div className='flex items-center mt-5 mb-10 w-full relative'>
                                <img src="/assets/img/Rectangledream.png" alt="Icon" class="w-full" />
                                <div className='absolute' style={{ right: "0" }}>
                                    <div className='grid justify-centerp-4 mt-[70px] mr-[100px]'>
                                        <div><span className='text-5xl font-medium'>Create your own </span><span className='text-5xl font-medium text-red-500'>Dream Girlfriend</span></div>
                                        <div className='text-center mt-[50px]'>
                                            <button class="bg-red-800 text-white font-medium rounded-2xl p-4" onClick={CreateDreamGF}>
                                                Create Your Dream Girlfrinend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-[24px] text-black text-center'>Meet our Best AI GirlFriends</div>
                            <div className='grid grid-cols-4 gap-[50px]'>
                                {
                                    imgdata.map((data, index) => (
                                        <div key={index} className='relative'>
                                            <img src={data.images} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                            <div className='absolute inset-0 flex flex-col justify-between opacity-0 opacity-100 transition-opacity duration-700'>
                                                <div className='flex justify-end p-4'>
                                                    <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                        <img src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-16" />
                                                    </button>
                                                </div>
                                                <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-4 opacity-0 opacity-100 transition-opacity duration-700 rounded-b-lg'>
                                                    <div className='flex justify-between py-4'>
                                                        <span className="text-2xl font-medium">Cataline</span>
                                                        <span className="text-2xl font-medium">29 Year</span>
                                                    </div>
                                                    <p>Weather report anchor in Vienna.<br /> Full of herself, she loves the attention she gets ...</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <Characters />
                        </>
                        :
                        <>
                            <div className='flex items-center mt-5 mb-10 w-full relative'>
                                <img src="/assets/img/boydream.png" alt="Icon" class="w-full rounded-xl" />
                                <div className='absolute' style={{ right: "0" }}>
                                    <div className='grid justify-centerp-4 mt-[70px] mr-[100px]'>
                                        <div><span className='text-5xl font-medium'>Create your own </span><span className='text-5xl font-medium text-red-500'>Dream Boyfriend</span></div>
                                        <div className='text-center mt-[50px]'>
                                            <button class="bg-red-800 text-white font-medium rounded-2xl p-4" onClick={CreateDreamBf}>
                                                Create Your Dream Boyfrinend
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-[24px] text-black text-center'>Meet our Best AI BoyFriends</div>

                            <div className='grid grid-cols-4 gap-[50px]'>
                                {
                                    images1.map((data, index) => (
                                        <div key={index} className='relative'>
                                            <img src={data.src} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' />
                                            <div className='absolute inset-0 flex flex-col justify-between opacity-0 opacity-100 transition-opacity duration-700'>
                                                <div className='flex justify-end p-4'>
                                                    <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                                        <img src="/assets/img/Group-icon.png" alt="Icon" className="w-16 h-16" />
                                                    </button>
                                                </div>
                                                <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-4 opacity-0 opacity-100 transition-opacity duration-700 rounded-b-lg'>
                                                    <div className='flex justify-between py-4'>
                                                        <span className="text-2xl font-medium">Cataline</span>
                                                        <span className="text-2xl font-medium">29 Year</span>
                                                    </div>
                                                    <p>Weather report anchor in Vienna.<br /> Full of herself, she loves the attention she gets ...</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <Characters />
                        </>
                }
                <Blogs />
                <Accordion />
                <Footer />

            </div>
            {showPopup && <LandingPopup onClose={handleClosePopup} />}
        </>
    );
};

export default LandingPage;
